import React, { Fragment } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Privacidad = () => {
    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        body{
                            background-color: #060A21;
                        }
                        
                        .centradoVertical {
                            margin: 0;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        
                        .btnIniciar {
                            background: #0d6efd;
                            border-color: #0d6efd;
                        }
                        
                        .btnSolicitar {
                            color: #464AC0;
                        }
                        
                        .bgImg {
                            height: 100%;
                            opacity: 0.2;
                            position: absolute;
                        }
                        
                        .logoHeader {
                            height: 8vw;
                            min-height: 50px;
                            max-height: 80px;
                        }

                        .codigo {
                            margin: 0.8rem;
                            text-align: center;
                            border-radius: 25%;
                            width: 2.8rem;
                            height: 3rem;
                            background-color: #dddddd;
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
                            border-color: #989898;
                            font-weight: bold;
                        }
                        
                        .codigo:focus {
                            background-color: white;
                            border-color: #b7b7b7;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-10 offset-md-1 my-5">
                    <div className="card bg-light"> 
                        <div className="card-body p-4">
                            <div className="row">
                                <div className="col-md-12 text-center mb-3">
                                    <img src={process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/logo_IAJU-Color.png'} className="d-inline-block mt-2 mb-4 logoHeader" alt="logo"/>
                                </div>
                            </div>

                            <h3 className="text-center">AVISO DE PRIVACIDAD INTEGRAL</h3>

                            <h5 className="text-start mt-5">I. Domicilio del responsable.</h5>
                            
                            <p>La Secretaría de Seguridad Pública del Estado de Aguascalientes, en lo sucesivo SSP, se encuentra ubicada en Av. Aguascalientes Oriente s/n, colonia Ex ejido Ojocaliente, C.P. 20190.</p>

                            <h5 className="text-start mt-5">II. Datos personales sometidos a tratamiento.</h5>
                            
                            <p>Los datos personales que recabamos podrán ser utilizados para las finalidades concernientes con la relación jurídica y/o administrativa y/o con la prestación de trámites y servicios de la SSP. Los datos personales que serán sometidos a tratamiento se enlistan a continuación de manera enunciativa más no limitativa:
                                <ul className="my-3">
                                    <li>Número telefónico</li>
                                    <li>Correo electrónico</li>
                                    <li>Clave única de Registro de Población (CURP)</li>
                                    <li>Lugar de nacimiento</li>
                                    <li>Fecha de nacimiento</li>
                                    <li>Nacionalidad</li>
                                    <li>Domicilio</li>
                                    <li>Firma autógrafa</li>
                                    <li>Edad</li>
                                    <li>Fotografía</li>
                                    <li>Color del iris</li>
                                    <li>Color de cabello</li>
                                    <li>Estatura</li>
                                    <li>Peso</li>
                                    <li>Grupo sanguíneo y del factor RH (dato personal sensible)</li>
                                    <li>Examen médico y psicométrico (dato personal sensible)</li>
                                    <li>Estudios de personalidad (dato personal sensible)</li>
                                    <li>Puesto o cargo que desempeña</li>
                                    <li>Trayectoria educativa</li>
                                    <li>Calidad migratoria</li>
                                    <li>Manifestación sobre la donación de órganos (dato personal sensible)</li>
                                    <li>Razón social</li>
                                </ul>
                            </p>

                            <p>Los datos personales que recabamos podrán ser utilizados para atender los siguientes trámites y servicios:
                                <ul className="my-3">
                                    <li>Aplicación Emergencias Carretera</li>
                                    <li>Aplicación Esquema Coordinado</li>
                                    <li>Aplicación “911 AGS”</li>
                                    <li>Autorización como prestador de servicio de seguridad privada</li>
                                    <li>Autorización para el establecimiento de escuela de manejo</li>
                                    <li>Calificación de infracciones</li>
                                    <li>Curso de educación vial</li>
                                    <li>Expedición de licencias de conducir</li>
                                    <li>Permiso para conducir para menor de edad</li>
                                    <li>Permiso para circular sin placas</li>
                                    <li>Recursos de revisión</li>
                                    <li>Renovación de licencias de conducir</li>
                                    <li>Reposición de licencias de conducir</li>
                                    <li>Reportes ciudadanos</li>
                                    <li>Revalidación de autorización como prestador de servicio de seguridad privada</li>
                                    <li>Revalidación de autorización de escuela de manejo</li>
                                    <li>Solicitudes de acceso a la información</li>
                                    <li>Solicitudes de protección de datos personales</li>
                                    <li>Servicio de llamadas de emergencias 911</li>
                                </ul>
                            </p>

                            <h5 className="text-start mt-5">III. Fundamento Legal.</h5>

                            <p>Los datos personales que el titular de la información proporcione, serán protegidos en términos de lo dispuesto por la Ley General de Protección de Datos Personales en Posesión de los Sujetos Obligados, Ley de Protección de Datos Personales en Posesión de los Sujetos Obligados del Estado de Aguascalientes y sus Municipios, Ley General de Transparencia y Acceso a la Información Pública, Ley de Transparencia y Acceso a la Información Pública del Estado de Aguascalientes y sus Municipios, Lineamientos Generales de Protección de Datos Personales para el Sector Público y Lineamientos para la Protección de Datos Personales del Estado de Aguascalientes y sus Municipios.</p>
                            <p>La SSP informa que el tratamiento de los datos personales se realiza en estricto apego al principio de legalidad, en ejercicio de facultades expresamente conferidas por la leyes aplicables, mismas que se encuentran establecidas en la Ley Orgánica de la Administración Pública Estatal; Ley General del Sistema Nacional de Seguridad Pública; Ley del Sistema Estatal de Seguridad Pública del Estado de Aguascalientes; Ley de Movilidad del Estado de Aguascalientes y Reglamento Interior de la Secretaría de Seguridad Pública del Estado.</p>
                            <p>
                                La SSP podrá realizar transferencias de datos personales sin necesidad de requerir el consentimiento del titular, en los siguientes supuestos:
                                <ol className="my-3">
                                    <li>Cuando la transferencia esté prevista en esta Ley u otras leyes, convenios o Tratados Internacionales suscritos y ratificados por México.</li>
                                    <li>Cuando la transferencia se realice entre responsables, siempre y cuando los datos personales se utilicen para el ejercicio de facultades propias, compatibles o análogas con la finalidad que motivó el tratamiento de los datos personales.</li>
                                    <li>Cuando la transferencia sea legalmente exigida para la investigación y persecución de los delitos, así como la procuración o administración de justicia.</li>
                                    <li>Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho ante autoridad competente, siempre y cuando medie el requerimiento de esta última.</li>
                                    <li>Cuando la transferencia sea necesaria para la prevención o el diagnóstico médico, la prestación de asistencia sanitaria, tratamiento médico o la gestión de servicios sanitarios, siempre y cuando dichos fines sean acreditados.</li>
                                    <li>Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el responsable y el titular.</li>
                                    <li>Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del titular, por el responsable y un tercero.</li>
                                    <li>Cuando se trate de los casos en los que el responsable no esté obligado a recabar el consentimiento del titular para el tratamiento y transmisión de sus datos personales, conforme a lo dispuesto en el artículo 22 de la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados.</li>
                                    <li>Cuando la transferencia sea necesaria por razones de seguridad nacional.</li>
                                </ol>

                                Las remisiones nacionales e internacionales de datos personales que se realicen entre responsable y encargado no requerirán ser informadas al titular, ni contar con su consentimiento.
                            </p>

                            <h5 className="text-start mt-5">IV. Los mecanismos, medios y procedimientos disponibles para ejercer los derechos ARCO.</h5>
                            
                            <p>En cualquier momento el titular, por sí o por conducto de su representante, podrá ejercer sus derechos de acceso, rectificación, cancelación u oposición al tratamiento de sus datos personales (derechos ARCO) directamente ante la Unidad de Transparencia de la SSP, o bien, a través del siguiente correo electrónico: jessica.villalpando@aguascalientes.gob.mx.</p>
                            <p>Tratándose de datos personales concernientes a personas fallecidas, la persona que acredita tener un interés jurídico, de conformidad con las leyes aplicables, podrá ejercer los derechos ARCO del fallecido, siempre que este último hubiere expresado fehacientemente su voluntad en tal sentido o que exista un mandato judicial para dicho efecto.</p>
                            <p>Para el ejercicio de los derechos ARCO, es necesario que el titular acredite su identidad, y en su caso la identidad y personalidad con la que actúe el representante. El titular podrá acreditar su identidad mediante identificación oficial, instrumentos electrónicos o mecanismos de autenticación permitidos por otras disposiciones legales o reglamentarias que permitan su identificación fehacientemente, o mediante aquellos mecanismos establecidos de manera previa, siempre y cuando permitan de forma inequívoca la acreditación de la identidad del titular</p>
                            <p>Cuando se promueva a través de un representante, este deberá acreditar su identidad y personalidad ante la SSP mediante copia simple de la identificación oficial del titular, identificación oficial del representante y el instrumento público, carta poder simple firmada ante dos testigos o declaración en comparecencia personal del titular.</p>
                            <p>Para su ejercicio, deberá presentar la solicitud para el ejercicio de los derechos ARCO ante la Unidad de Transparencia de esta SSP, a través de un escrito libre, formatos, medios electrónicos o cualquier otro medio que establezca la SSP, o bien, a través de la Plataforma Nacional de Transparencia (PNT).</p>
                            <p>
                                Dicha solicitud deberá contener la siguiente información:
                                <ol className="my-3">
                                    <li>El nombre completo del titular y su domicilio o cualquier otro medio para oír y recibir notificaciones;</li>
                                    <li>La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos ARCO, salvo que se trate del derecho de acceso; www.aguascalientes.gob.mx Av. Aguascalientes Ote. S/N, Col. Ex-ejido Ojocaliente, C.P. 20190 449 910 2055 EXT. 6678</li>
                                    <li>La descripción del derecho ARCO que se pretende ejercer, o bien, lo que solicita el titular;</li>
                                    <li>Los documentos que acrediten la identidad del titular y, en su caso la personalidad e identidad de su representante;</li>
                                    <li>El área responsable que trata los datos personales y ante el cual se presenta la solicitud, en caso de ser posible; y</li>
                                    <li>Cualquier otro elemento o documento que facilite la localización de los datos personales, en su caso. En caso de resultar procedente el ejercicio de los derecho ARCO, la SSP deberá hacerlo efectivo dentro del plazo de quince días contados a partir del día siguiente a que se le haya notificado la respuesta al titular. El ejercicio de los derechos ARCO deberá ser gratuito. Sólo podrán realizarse cobros para recuperar los costos de reproducción, certificación o envío.</li>
                                </ol>
                            </p>
                            <p>
                                El ejercicio de los derechos ARCO no será procedente cuando:
                                <ol className="my-3">
                                    <li>El titular o su representante no estén debidamente acreditados para ello;</li>
                                    <li>Los datos personales no se encuentren en posesión del responsable;</li>
                                    <li>Exista un impedimento legal;</li>
                                    <li>Se lesionen los derechos de un tercero;</li>
                                    <li>Se obstaculicen actuaciones judiciales o administrativas;</li>
                                    <li>Exista una resolución de autoridad competente que restrinja el acceso a los datos personales o no permita la rectificación, cancelación u oposición de los mismos;</li>
                                    <li>La cancelación u oposición haya sido previamente realizada, respecto al mismo titular, responsable y datos personales;</li>
                                    <li>La Secretaría de Seguridad Pública del Estado de Aguascalientes no sea competente;</li>
                                    <li>Sean necesarios para proteger intereses jurídicamente tutelados del titular; o</li>
                                    <li>Sean necesarios para dar cumplimiento a obligaciones legalmente adquiridas por el titular.</li>
                                </ol>

                                Lo anterior, de conformidad con el Capítulo III de la Ley de Protección de Datos Personales en Posesión de los Sujetos Obligados del Estado de Aguascalientes y sus Municipios.
                            </p>
                            <p>Contra la negativa de dar trámite a una solicitud para el ejercicio de los derechos ARCO, o bien, la inconformidad del titular por la respuesta recibida o la falta del responsable, procederá la interposición del recurso de revisión contemplado en el artículo 75 de la Ley de Protección de Datos Personales en Posesión de los Sujetos Obligados del Estado de Aguascalientes y sus Municipios.</p>

                            <h5 className="text-start mt-5">V. Domicilio de la Unidad de Transparencia.</h5>

                            <p>La Secretaría de Seguridad Pública del Estado de Aguascalientes cuenta con una Unidad de Transparencia, la cual se encuentra en el segundo piso del edificio ubicado en Av. Aguascalientes Oriente s/n, colonia Ex ejido Ojocaliente, en la Ciudad Aguascalientes, Ags., número telefónico 449 910 20 55 extensión 6678.</p>
                            <p>Para presentar solicitud de acceso a la información o de datos personales, podrá acudir directamente a la Unidad de Transparencia, o bien, ingresar al siguiente sitio web https://www.plataformadetransparencia.org.mx/</p>
                            <p>También podrá hacerlo a través del correo electrónico: jessica.villalpando@aguascalientes.gob.mx</p>

                            <h5 className="text-start mt-5">VI. Medios a través de los cuales la SSP comunicará a los titulares los cambios al aviso de privacidad.</h5>

                            <p>La SSP podrá modificar el presente aviso de privacidad y sus prácticas respecto al manejo de su información personal; sin embargo, cualquier modificación sustancial que le afecte será comunicada de la página oficial de la Secretaría de Seguridad Pública del Estado que se encuentra en la siguiente liga electrónica http://www.aguascalientes.gob.mx/ssp</p>
                            <p>Si usted no manifiesta su oposición o negativa para el uso y tratamiento de su información personal, se entenderá que se ha otorgado consentimiento para ello.</p>
                            <p>El titular de la información podrá oponerse a los nuevos términos a través de la Unidad de Transparencia de esta Secretaría.</p>
                            <p>La Secretaría de Seguridad Pública del Estado de Aguascalientes, informa que el Instituto de Transparencia del Estado de Aguascalientes, es la máxima autoridad en materia de protección de datos personales, para confirmar, modificar o revocar las determinaciones en las que se declare la inexistencia de los datos personales, o se declare improcedente por cualquier causa el ejercicio de alguno de los derechos ARCO establecidos en la Ley de Protección de Datos Personales en Posesión de los Sujetos Obligados del Estado de Aguascalientes y sus Municipios.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Privacidad;