import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import { formatNumber, sortTableNumberCupo, sortTableNumberSolicitados, sortTableNumberRealizados, sortTableDateLimite, sortTableStatusStatus } from '../../utils/generalFunctions';

import { buscarDashboard } from '../../actions/dashboard';

const DashboardEmpresa = () => {

    const dispatch =  useDispatch();

    const { detalles } = useSelector(state => state.dashboard);
    const { usuario, empresa } = useSelector(state => state.auth);

    const [dashboardData, setDashboardData] = useState({
        promociones_realizadas: null, 
        promociones_canjeadas: null, 
        promociones_no_canjeadas: null, 
        promociones_activas: null, 
        cupones_solicitados: null, 
        cupones_canjeados: null, 
        jovenes_alcanzados: null, 
        mejores_promociones: [], 
        peores_promociones: []
    });

    const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de' };

    const columns = [
        {
            name: 'Nombre',
            sortable: true, 
            selector: row => row.nombre,
            grow: 3
        },
        {
            name: 'Cupo límite',
            sortable: true, 
            sortFunction: sortTableNumberCupo, 
            selector: row => row.cupo ? formatNumber(row.cupo, 0, '', '') : 'Sin limite'
        },
        {
            name: 'Fecha límite', 
            sortable: true, 
            sortFunction: sortTableDateLimite, 
            cell: item => item.limite ? new Date(item.limite).toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Sin limite'
        },
        {
            name: 'Canjes solicitados',
            sortable: true, 
            sortFunction: sortTableNumberSolicitados, 
            selector: row => formatNumber(row.canjes_solicitados, 0, '', '')
        },
        {
            name: 'Canjes realizados',
            sortable: true, 
            sortFunction: sortTableNumberRealizados, 
            selector: row => formatNumber(row.canjes_realizados, 0, '', '')
        },
        {
            name: 'Status',
            sortable: true, 
            sortFunction: sortTableStatusStatus, 
            selector: row => {
                switch(row.status){
                    case 0:
                        return(
                            <h5><Badge pill bg="success">Activa</Badge></h5>
                        )
                    case 1:
                        return(
                            <h5><Badge pill bg="warning">Inactiva</Badge></h5>
                        )
                    case 2:
                        return(
                            <h5><Badge pill bg="danger">Finalizada</Badge></h5>
                        )
                    default:
                        break;
                }
            }, 
            grow: 2
        },
        {
            name: '',
            cell: row => {
                if(row._id){
                    return(
                        <div className="text-right w-100">
                            <Link className="btn btn-sm btn-link px-2 float-end" to={`/promociones/detalles/${row._id}`}><i className="fas fa-circle-info text-info"></i></Link>
                        </div>
                    )
                }else{
                    return(<></>)
                }
            }
        }
    ];

    useEffect(() => {
        dispatch(buscarDashboard('empresa'));
    }, []);

    useEffect(() => {
        if(detalles){
            setDashboardData({
                ...dashboardData,
                promociones_realizadas: detalles.promociones_realizadas, 
                promociones_canjeadas: detalles.promociones_canjeadas, 
                promociones_no_canjeadas: detalles.promociones_no_canjeadas, 
                promociones_activas: detalles.promociones_activas, 
                cupones_solicitados: detalles.cupones_solicitados, 
                cupones_canjeados: detalles.cupones_canjeados, 
                jovenes_alcanzados: detalles.jovenes_alcanzados, 
                usuarios: detalles.usuarios, 
                mejores_promociones: detalles.mejores_promociones, 
                peores_promociones: detalles.peores_promociones
            });
        }        
    }, [detalles]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                <style>{`
                        .imagen_empresa {
                            height: 100px;
                            width: 100px;
                            border-radius: 50%;
                            border: 2px solid #111f62;
                            margin-right: 15px;
                        }

                        .titulo {
                            color: #111f62;
                        }

                        .indicador {
                            font-size: 18px;
                            margin: 0;
                        }

                        .subtitulo {
                            margin: 0;
                            font-weight: 700;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
        
            <div className="col-md-12 d-flex align-items-center mb-2">
                <img className="imagen_empresa" src={empresa.url_perfil_g != null ? empresa.url_perfil_g : process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'}/>
                <h4 className="titulo">
                    Bienvenido {[usuario.nombre, usuario.apellido_paterno, usuario.apellido_materno].join(' ')}
                </h4>
            </div>

            <div className="col-md-6 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Promociones</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.promociones_realizadas != null && dashboardData.promociones_realizadas != undefined ? formatNumber(dashboardData.promociones_realizadas, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">realizadas</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.promociones_canjeadas != null && dashboardData.promociones_canjeadas != undefined ? formatNumber(dashboardData.promociones_canjeadas, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">canjeadas</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.promociones_no_canjeadas != null && dashboardData.promociones_no_canjeadas != undefined ? formatNumber(dashboardData.promociones_no_canjeadas, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">no canjeadas</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.promociones_activas != null && dashboardData.promociones_activas != undefined ? formatNumber(dashboardData.promociones_activas, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">activas</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-3 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Cupones</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.cupones_solicitados != null && dashboardData.cupones_solicitados != undefined ? formatNumber(dashboardData.cupones_solicitados, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">solicitados</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.cupones_canjeados != null && dashboardData.cupones_canjeados != undefined ? formatNumber(dashboardData.cupones_canjeados, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">canjeados</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-3 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Personas</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.jovenes_alcanzados != null && dashboardData.jovenes_alcanzados != undefined ? formatNumber(dashboardData.jovenes_alcanzados, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">jovenes</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.usuarios != null && dashboardData.usuarios != undefined ? formatNumber(dashboardData.usuarios, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">usuarios</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Mejores promociones</h5>
                        </div>
                        <div className="row mt-2">
                            <DataTable 
                                columns={columns}
                                data={dashboardData.mejores_promociones}
                                pagination
                                paginationPerPage={5}
                                paginationRowsPerPageOptions={[5]}
                                paginationComponentOptions={paginationOptions}
                                highlightOnHover={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Peores promociones</h5>
                        </div>
                        <div className="row mt-2">
                            <DataTable 
                                columns={columns}
                                data={dashboardData.peores_promociones}
                                pagination
                                paginationPerPage={5}
                                paginationRowsPerPageOptions={[5]}
                                paginationComponentOptions={paginationOptions}
                                highlightOnHover={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DashboardEmpresa;