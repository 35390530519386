import React, { useState, useEffect, createRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { recoverApp, deleteApp } from '../../actions/auth';
import { setAlert } from '../../actions/alert';

const Eliminacion = () => {

    const dispatch = useDispatch();

    const { codigo } = useSelector(state => state.auth);
    
    const [solicitarData, setSolicitarData] = useState({
        email: null,
        codigo_solicitar_1: '',
        codigo_solicitar_2: '',
        codigo_solicitar_3: '',
        codigo_solicitar_4: '',
        solicitando: true,
        confirmando: false,
        eliminando: false
    });

    let ref_numero_2 = createRef();
    let ref_numero_3 = createRef();
    let ref_numero_4 = createRef();

    /* Solicitar */
    const onChangeEmailSolicitar = (e) => {
        setSolicitarData({
            ...solicitarData, [e.target.name]: e.target.value
        })
    }

    const handleKeyPressSolicitar = (e) => {
        if(e.keyCode === 13){
            submitSolicitar();
        }
    }

    const submitSolicitar = async () => {
        dispatch(recoverApp(solicitarData.email));
    }

    /* Confirmar */
    const onChangeCodigoSolicitar = (e) => {
        setSolicitarData({
            ...solicitarData, [e.target.name]: e.target.value
        });
    }

    const handleKeyPressConfirmar = (e) => {
        if(e.keyCode === 13){
            submitConfirmar();
        }
    }

    const submitConfirmar = async () => {

        let {codigo_solicitar_1, codigo_solicitar_2, codigo_solicitar_3, codigo_solicitar_4} = solicitarData;

        let codigo_usuario = codigo_solicitar_1 + '' + codigo_solicitar_2 + '' + codigo_solicitar_3 + '' + codigo_solicitar_4;

        if (codigo == codigo_usuario) {
            dispatch(setAlert('Código correcto', 'success'));
            
            setSolicitarData({
                ...solicitarData,
                codigo_solicitar_1: '',
                codigo_solicitar_2: '',
                codigo_solicitar_3: '',
                codigo_solicitar_4: '',
                solicitando: false,
                confirmando: false,
                eliminando: true
            });
        }else{
            dispatch(setAlert('El código no coincide', 'danger'));
        }
    }

    /* Eliminar */
    const submitEliminar = async () => {
        dispatch(deleteApp({ email: solicitarData.email }));

        setSolicitarData({
            ...solicitarData,
            email: null,
            codigo_solicitar_1: '',
            codigo_solicitar_2: '',
            codigo_solicitar_3: '',
            codigo_solicitar_4: '',
            solicitando: true,
            confirmando: false,
            eliminando: false
        });

    }

    /* Funciones generales */
    const handleOpenDelete = () => {
        setSolicitarData({
            ...solicitarData,
            email: null,
            codigo_solicitar_1: '',
            codigo_solicitar_2: '',
            codigo_solicitar_3: '',
            codigo_solicitar_4: '',
            solicitando: true,
            confirmando: false,
            eliminando: false
        });
    }

    useEffect(() => {
        if(codigo){
            setSolicitarData({
                ...solicitarData, 
                codigo_solicitar_1: '',
                codigo_solicitar_2: '',
                codigo_solicitar_3: '',
                codigo_solicitar_4: '',
                solicitando: false,
                confirmando: true,
                eliminando: false
            });
        }
    }, [codigo]);

    useEffect(() => {
        if(solicitarData.codigo_solicitar_1 != ''){
            ref_numero_2.current.focus();
        }
    }, [solicitarData.codigo_solicitar_1]);

    useEffect(() => {
        if(solicitarData.codigo_solicitar_2 != ''){
            ref_numero_3.current.focus();
        }
    }, [solicitarData.codigo_solicitar_2]);
    
    useEffect(() => {
        if(solicitarData.codigo_solicitar_3 != ''){
            ref_numero_4.current.focus();
        }
    }, [solicitarData.codigo_solicitar_3]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        body{
                            background-color: #060A21;
                        }
                        
                        .centradoVertical {
                            margin: 0;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        
                        .btnIniciar {
                            background: #0d6efd;
                            border-color: #0d6efd;
                        }
                        
                        .btnSolicitar {
                            color: #464AC0;
                        }
                        
                        .bgImg {
                            height: 100%;
                            opacity: 0.2;
                            position: absolute;
                        }
                        
                        .logoHeader {
                            height: 8vw;
                            min-height: 50px;
                            max-height: 80px;
                        }

                        .codigo {
                            margin: 0.8rem;
                            text-align: center;
                            border-radius: 25%;
                            width: 2.8rem;
                            height: 3rem;
                            background-color: #dddddd;
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
                            border-color: #989898;
                            font-weight: bold;
                        }
                        
                        .codigo:focus {
                            background-color: white;
                            border-color: #b7b7b7;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-6 offset-md-3 centradoVertical">
                    <div className="card bg-light"> 
                        <div className="card-body">
                            {
                                solicitarData.solicitando ?
                                    <div className="row">
                                        <div className="col-md-12 text-center mb-3">
                                            <img src={process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/logo_IAJU-Color.png'} className="d-inline-block mt-2 mb-4 logoHeader" alt="logo"/>
                                            <h5 className="card-title">Eliminación de cuenta</h5>
                                        </div>

                                        <div className="col-md-12 p-3">
                                            <p>Ingresa tu cuenta de correo para eliminar tu cuenta de usuario en la App Tarjeta Joven. Te enviaremos un código de 4 dígitos a tu correo para este proceso.</p>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="input-group mb-3">
                                                <div className="form-floating">
                                                    <input type="email" className="form-control" placeholder="Correo" id="email_recuperar" name="email" value={solicitarData.email || ''} onChange={e => onChangeEmailSolicitar(e)} onKeyDown={handleKeyPressSolicitar}></input>
                                                    <label htmlFor="email_recuperar">Correo</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group mt-3 text-center">
                                                <button type="button" className="btn btn-primary w-100" onClick={() => submitSolicitar()}>Envíar código</button>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                            }

                            {
                                solicitarData.confirmando ?
                                    <div className="row">
                                        <div className="col-md-12 text-center mb-3">
                                            <img src={process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/logo_IAJU-Color.png'} className="d-inline-block mt-2 mb-4 logoHeader" alt="logo"/>
                                            <h5 className="card-title">Código de eliminación</h5>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group text-center">
                                                <input
                                                    type="text"
                                                    className="codigo"
                                                    name="codigo_solicitar_1"
                                                    min="0"
                                                    max="9"
                                                    maxLength="1"
                                                    value={ solicitarData.codigo_solicitar_1 || ''}
                                                    onChange={e => onChangeCodigoSolicitar(e)}
                                                    onKeyDown={handleKeyPressConfirmar}></input>
                                                <input
                                                    type="text"
                                                    className="codigo"
                                                    name="codigo_solicitar_2"
                                                    min="0"
                                                    max="9"
                                                    maxLength="1"
                                                    ref={ref_numero_2}
                                                    value={ solicitarData.codigo_solicitar_2 || ''}
                                                    onChange={e => onChangeCodigoSolicitar(e)}
                                                    onKeyDown={handleKeyPressConfirmar}></input>
                                                <input
                                                    type="text"
                                                    className="codigo"
                                                    name="codigo_solicitar_3"
                                                    min="0"
                                                    max="9"
                                                    maxLength="1"
                                                    ref={ref_numero_3}
                                                    value={ solicitarData.codigo_solicitar_3 || ''}
                                                    onChange={e => onChangeCodigoSolicitar(e)}
                                                    onKeyDown={handleKeyPressConfirmar}></input>
                                                <input
                                                    type="text"
                                                    className="codigo"
                                                    name="codigo_solicitar_4"
                                                    min="0"
                                                    max="9"
                                                    maxLength="1"
                                                    ref={ref_numero_4}
                                                    value={ solicitarData.codigo_solicitar_4 || ''}
                                                    onChange={e => onChangeCodigoSolicitar(e)}
                                                    onKeyDown={handleKeyPressConfirmar}></input>
                                            </div>
                                        </div>

                                        <div className="col-md-12 form-group text-left">
                                            <button type="button" className="btn btn-link btnRecuperar" onClick={() => handleOpenDelete()}>Volver al inicio</button>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group mt-3 text-center">
                                                <button type="button" className="btn btn-primary w-100" onClick={() => submitConfirmar()}>Validar código</button>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                            }

                            {
                                solicitarData.eliminando ?
                                    <div className="row">
                                        <div className="col-md-12 text-center mb-3">
                                            <img src={process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/logo_IAJU-Color.png'} className="d-inline-block mt-2 mb-4 logoHeader" alt="logo"/>
                                            <h5 className="card-title">Eliminación de cuenta</h5>
                                        </div>

                                        <div className="col-md-12 p-3">
                                            <p>¿Estás completamente seguro de eliminar tu cuenta de la App Tarjeta Joven? Una vez que concluya el proceso no podrás recuperar tu cuenta ni ninguno de los datos en ella.</p>
                                        </div>

                                        <div className="col-md-12 form-group text-left">
                                            <button type="button" className="btn btn-link btnRecuperar" onClick={() => handleOpenDelete()}>Volver al inicio</button>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group mt-3 text-center">
                                                <button type="button" className="btn btn-primary w-100" onClick={() => submitEliminar()}>Eliminar cuenta</button>
                                            </div>
                                        </div>
                                    </div>
                                :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Eliminacion;