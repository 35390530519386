import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { unsuscribe } from '../../actions/auth';

const Desuscripcion = () => {

    const dispatch = useDispatch();

    const { id } = useParams();
    
    const [desuscripcionData, setDesuscripcionData] = useState({
        id: null, 
        enviado: false
    });

    /* Solicitar */
    const submitSolicitar = async () => {
        setDesuscripcionData({
            ...desuscripcionData, 
            enviado: true
        });

        dispatch(unsuscribe(desuscripcionData.id));
    }

    useEffect(() => {
        if(id){
            setDesuscripcionData({
                ...desuscripcionData, 
                id
            });
        }
    }, [id]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        body{
                            background-color: #060A21;
                        }
                        
                        .centradoVertical {
                            margin: 0;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        
                        .btnIniciar {
                            background: #0d6efd;
                            border-color: #0d6efd;
                        }
                        
                        .btnSolicitar {
                            color: #464AC0;
                        }
                        
                        .bgImg {
                            height: 100%;
                            opacity: 0.2;
                            position: absolute;
                        }
                        
                        .logoHeader {
                            height: 8vw;
                            min-height: 50px;
                            max-height: 80px;
                        }

                        .codigo {
                            margin: 0.8rem;
                            text-align: center;
                            border-radius: 25%;
                            width: 2.8rem;
                            height: 3rem;
                            background-color: #dddddd;
                            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
                            border-color: #989898;
                            font-weight: bold;
                        }
                        
                        .codigo:focus {
                            background-color: white;
                            border-color: #b7b7b7;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>

            <div className="row">
                <div className="col-md-6 offset-md-3 centradoVertical">
                    <div className="card bg-light"> 
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12 text-center mb-3">
                                    <img src={process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/logo_IAJU-Color.png'} className="d-inline-block mt-2 mb-4 logoHeader" alt="logo"/>
                                    <h5 className="card-title">Desuscripción de correos electrónicos</h5>
                                </div>

                                <div className="col-md-12 p-3">
                                    <p>¿Realmente deseas dejar de recibir correos electrónicos por parte del Instituto de la Juventud? Este proceso no podrá revertirse una vez que presiones en "Aceptar".</p>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group mt-3 text-center">
                                        <button type="button" className="btn btn-primary w-100" onClick={() => submitSolicitar()} disabled={desuscripcionData.enviado}>Aceptar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Desuscripcion;