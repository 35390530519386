import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { formatNumber } from '../../utils/generalFunctions';

import { buscarDashboard } from '../../actions/dashboard';

const DashboardGimnasio = () => {

    const dispatch =  useDispatch();

    const { detalles } = useSelector(state => state.dashboard);
    const { usuario } = useSelector(state => state.auth);

    const [dashboardData, setDashboardData] = useState({
        gimnasio_mas: null, 
        gimnasio_menos: null, 
        dia_mas: null, 
        dia_menos: null, 
        horario_mas: null, 
        horario_menos: null, 
        usuarios_inscritos: null, 
        usuarios_activos: null, 
        usuarios_adeudando: null, 
        usuarios_inactivos: null, 
        usuario_mas: null, 
        usuario_antiguo: null, 
        usuario_reciente: null, 
        ultimo_inscrito: null, 
        ultimo_acceso: null, 
        ultimo_renovacion: null
    });

    useEffect(() => {
        dispatch(buscarDashboard('gimnasio'));
    }, []);

    useEffect(() => {
        if(detalles){
            setDashboardData({
                ...dashboardData, 
                gimnasio_mas: detalles.gimnasio_mas,
                gimnasio_menos: detalles.gimnasio_menos,
                dia_mas: detalles.dia_mas,
                dia_menos: detalles.dia_menos,
                horario_mas: detalles.horario_mas,
                horario_menos: detalles.horario_menos,
                usuarios_inscritos: detalles.usuarios_inscritos,
                usuarios_activos: detalles.usuarios_activos,
                usuarios_adeudando: detalles.usuarios_adeudando,
                usuarios_inactivos: detalles.usuarios_inactivos,
                usuario_mas: detalles.usuario_mas,
                usuario_antiguo: detalles.usuario_antiguo,
                usuario_reciente: detalles.usuario_reciente,
                ultimo_inscrito: detalles.ultimo_inscrito,
                ultimo_acceso: detalles.ultimo_acceso,
                ultimo_renovacion: detalles.ultimo_renovacion
            });
        }        
    }, [detalles]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .imagen_perfil {
                            height: 100px;
                            width: 100px;
                            border-radius: 50%;
                            border: 2px solid #111f62;
                            margin-right: 15px;
                        }

                        .titulo {
                            color: #111f62;
                        }

                        .indicador {
                            font-size: 18px;
                            margin: 0;
                        }

                        .subtitulo {
                            margin: 0;
                            font-weight: 700;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
        
            <div className="col-md-12 d-flex align-items-center mb-2">
                <img className="imagen_perfil" src={usuario.url_perfil_g != null ? usuario.url_perfil_g : process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'}/>
                <h4 className="titulo">
                    Bienvenido {[usuario.nombre, usuario.apellido_paterno, usuario.apellido_materno].join(' ')}
                </h4>
            </div>

            <div className="col-md-12 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Asistencias</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.gimnasio_mas != null && dashboardData.gimnasio_mas != undefined ? dashboardData.gimnasio_mas : 'Sin definir'}</p>
                                <p className="subtitulo">gimnasio más asistido</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.gimnasio_menos != null && dashboardData.gimnasio_menos != undefined ? dashboardData.gimnasio_menos : 'Sin definir'}</p>
                                <p className="subtitulo">gimnasio menos asistido</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.dia_mas != null && dashboardData.dia_mas != undefined ? dashboardData.dia_mas : 'Sin definir'}</p>
                                <p className="subtitulo">día más asistido</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.dia_menos != null && dashboardData.dia_menos != undefined ? dashboardData.dia_menos : 'Sin definir'}</p>
                                <p className="subtitulo">día menos asistido</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.horario_mas != null && dashboardData.horario_mas != undefined ? dashboardData.horario_mas : 'Sin definir'}</p>
                                <p className="subtitulo">horario más asistido</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.horario_menos != null && dashboardData.horario_menos != undefined ? dashboardData.horario_menos : 'Sin definir'}</p>
                                <p className="subtitulo">horario menos asistido</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Usuarios</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.usuarios_inscritos != null && dashboardData.usuarios_inscritos != undefined ? formatNumber(dashboardData.usuarios_inscritos, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">inscritos</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.usuarios_activos != null && dashboardData.usuarios_activos != undefined ? formatNumber(dashboardData.usuarios_activos, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">activos</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.usuarios_adeudando != null && dashboardData.usuarios_adeudando != undefined ? formatNumber(dashboardData.usuarios_adeudando, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">adeudando</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.usuarios_inactivos != null && dashboardData.usuarios_inactivos != undefined ? formatNumber(dashboardData.usuarios_inactivos, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">inactivos</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-8 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Destacados</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.usuario_mas != null && dashboardData.usuario_mas != undefined ? dashboardData.usuario_mas : 'Sin definir'}</p>
                                <p className="subtitulo">usuario que más asiste</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.usuario_antiguo != null && dashboardData.usuario_antiguo != undefined ? dashboardData.usuario_antiguo : 'Sin definir'}</p>
                                <p className="subtitulo">usuario más antiguo</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.usuario_reciente != null && dashboardData.usuario_reciente != undefined ? dashboardData.usuario_reciente : 'Sin definir'}</p>
                                <p className="subtitulo">usuario más reciente</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-12 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Mis acciones</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.ultimo_inscrito != null && dashboardData.ultimo_inscrito != undefined ? dashboardData.ultimo_inscrito : 'Sin definir'}</p>
                                <p className="subtitulo">último inscrito</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.ultimo_acceso != null && dashboardData.ultimo_acceso != undefined ? dashboardData.ultimo_acceso : 'Sin definir'}</p>
                                <p className="subtitulo">último acceso</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.ultimo_renovacion != null && dashboardData.ultimo_renovacion != undefined ? dashboardData.ultimo_renovacion : 'Sin definir'}</p>
                                <p className="subtitulo">última renovación</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DashboardGimnasio;