import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { formatNumber } from '../../utils/generalFunctions';

import { buscarDashboard } from '../../actions/dashboard';

const DashboardNutricional = () => {

    const dispatch =  useDispatch();

    const { detalles } = useSelector(state => state.dashboard);
    const { usuario } = useSelector(state => state.auth);

    const [dashboardData, setDashboardData] = useState({
        asesorias_totales: null, 
        asesorias_pendientes: null, 
        asesorias_activas: null, 
        asesorias_finalizadas: null, 
        dia_mas: null, 
        dia_menos: null, 
        horario_mas: null, 
        horario_menos: null, 
        usuario_asesorado: null, 
        usuario_citas: null, 
        usuario_reciente: null, 
        ultimo_ingreso: null, 
        ultimo_citado: null, 
        ultima_alta: null, 
        mensajes: null
    });

    useEffect(() => {
        dispatch(buscarDashboard('nutricional'));
    }, []);

    useEffect(() => {
        if(detalles){
            setDashboardData({
                ...dashboardData, 
                asesorias_totales: detalles.asesorias_totales, 
                asesorias_pendientes: detalles.asesorias_pendientes, 
                asesorias_activas: detalles.asesorias_activas, 
                asesorias_finalizadas: detalles.asesorias_finalizadas, 
                dia_mas: detalles.dia_mas, 
                dia_menos: detalles.dia_menos, 
                horario_mas: detalles.horario_mas, 
                horario_menos: detalles.horario_menos, 
                usuario_asesorado: detalles.usuario_asesorado, 
                usuario_citas: detalles.usuario_citas, 
                usuario_reciente: detalles.usuario_reciente, 
                ultimo_ingreso: detalles.ultimo_ingreso, 
                ultimo_citado: detalles.ultimo_citado, 
                ultima_alta: detalles.ultima_alta, 
                mensajes: detalles.mensajes
            });
        }        
    }, [detalles]);

    return (
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        .imagen_perfil {
                            height: 100px;
                            width: 100px;
                            border-radius: 50%;
                            border: 2px solid #111f62;
                            margin-right: 15px;
                        }

                        .titulo {
                            color: #111f62;
                        }

                        .indicador {
                            font-size: 18px;
                            margin: 0;
                        }

                        .subtitulo {
                            margin: 0;
                            font-weight: 700;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
        
            <div className="col-md-12 d-flex align-items-center mb-2">
                <img className="imagen_perfil" src={usuario.url_perfil_g != null ? usuario.url_perfil_g : process.env.REACT_APP_PUBLIC_ROUTE + '/assets/images/perfil_placeholder.png'}/>
                <h4 className="titulo">
                    Bienvenido {[usuario.nombre, usuario.apellido_paterno, usuario.apellido_materno].join(' ')}
                </h4>
            </div>

            <div className="col-md-6 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Asesorias</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.asesorias_totales != null && dashboardData.asesorias_totales != undefined ? formatNumber(dashboardData.asesorias_totales, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">totales</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.asesorias_pendientes != null && dashboardData.asesorias_pendientes != undefined ? formatNumber(dashboardData.asesorias_pendientes, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">pendientes</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.asesorias_activas != null && dashboardData.asesorias_activas != undefined ? formatNumber(dashboardData.asesorias_activas, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">activas</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.asesorias_finalizadas != null && dashboardData.asesorias_finalizadas != undefined ? formatNumber(dashboardData.asesorias_finalizadas, 0, '', '') : 'Sin definir'}</p>
                                <p className="subtitulo">finalizadas</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-3 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Dias</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.dia_mas != null && dashboardData.dia_mas != undefined ? dashboardData.dia_mas : 'Sin definir'}</p>
                                <p className="subtitulo">más citado</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.dia_menos != null && dashboardData.dia_menos != undefined ? dashboardData.dia_menos : 'Sin definir'}</p>
                                <p className="subtitulo">menos citado</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-3 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Horarios</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.horario_mas != null && dashboardData.horario_mas != undefined ? dashboardData.horario_mas : 'Sin definir'}</p>
                                <p className="subtitulo">más citado</p>
                            </div>
                            <div className="col">
                                <p className="indicador">{dashboardData.horario_menos != null && dashboardData.horario_menos != undefined ? dashboardData.horario_menos : 'Sin definir'}</p>
                                <p className="subtitulo">menos citado</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-12 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Destacados</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.usuario_asesorado != null && dashboardData.usuario_asesorado != undefined ? dashboardData.usuario_asesorado : 'Sin definir'}</p>
                                <p className="subtitulo">usuario más asesorado</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.usuario_citas != null && dashboardData.usuario_citas != undefined ? dashboardData.usuario_citas : 'Sin definir'}</p>
                                <p className="subtitulo">usuario con mas citas</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.usuario_reciente != null && dashboardData.usuario_reciente != undefined ? dashboardData.usuario_reciente : 'Sin definir'}</p>
                                <p className="subtitulo">usuario más reciente</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-12 mt-3">
                <div className="card bg-light">
                    <div className="card-body">
                        <div className="card-title mb-3">
                            <h5 className="titulo">Mis acciones</h5>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <p className="indicador">{dashboardData.ultimo_ingreso != null && dashboardData.ultimo_ingreso != undefined ? dashboardData.ultimo_ingreso : 'Sin definir'}</p>
                                <p className="subtitulo">último ingreso</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.ultimo_citado != null && dashboardData.ultimo_citado != undefined ? dashboardData.ultimo_citado : 'Sin definir'}</p>
                                <p className="subtitulo">último citado</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.ultima_alta != null && dashboardData.ultima_alta != undefined ? dashboardData.ultima_alta : 'Sin definir'}</p>
                                <p className="subtitulo">última alta</p>
                            </div>

                            <div className="col">
                                <p className="indicador">{dashboardData.mensajes != null && dashboardData.mensajes != undefined ? dashboardData.mensajes : 'Sin definir'}</p>
                                <p className="subtitulo">mensajes sin leer</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DashboardNutricional;